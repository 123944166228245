#google_translate_element {
   display: inline-block;
   position: relative;
   display: none;
   overflow: hidden;
   flex-shrink: 0;

   &.ready {
      margin-left: 32px;
      display: inline-block;
   }

   .goog-te-select {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      padding: 0;
      opacity: 0 !important;
   }

   .goog-te-gadget {
      border: 0;
      width: 100%;
      padding-bottom: 1px;
      background-color: transparent;
      font-size: 0 !important;
      position: relative;
      display: inline-flex;
      align-items: center;
      overflow: hidden;

      .goog-te-gadget-simple {
         display: none !important;
      }

      > * {
         font-size: 1rem !important;
      }

      &::before {
         content: '';
         width: 23px;
         height: 23px;
         background-image: url(/aspinclude/vismoWeb5/html/images/gte-icon.svg) !important;
         background-position: 0 0 !important;
         background-size: 100% auto;
         background-repeat: no-repeat;
      }

      .goog-te-gadget-icon {
         + span {
            display: none;
         }
      }
   }
}

#jazyky {
   &:empty {
      display: none;
   }

   ul {
      list-style-type: none;
      margin: 0 5px 0 0;
      padding: 0;
      display: inline-flex;
      align-items: center;

      li {
         margin: 0 5px;
         font-size: 0;

         a::before {
            font-size: rem(14px);
         }

         .googleTranslateActiveLanguage {
            font-weight: bold;
         }

         .language-en::before {
            content: 'EN';
         }
         .language-de::before {
            content: 'DE';
         }
         .language-fr::before {
            content: 'FR';
         }
         .language-pl::before {
            content: 'PL';
         }
         .language-ru::before {
            content: 'RU';
         }
         .language-hu::before {
            content: 'HU';
         }
         .language-cs::before {
            content: 'CS';
         }

         @include link {
            text-decoration: none;
         }

         @include link-over {
            text-decoration: underline;
         }
      }
   }
}

body[style] {
   top: 0 !important;
}

#goog-gt-tt,
#goog-gt-tt + div > object,
iframe.goog-te-menu-frame,
iframe.goog-te-banner-frame {
   display: none !important;
}
