
/*************************
* ŠABLONY
*************************/


/*************************
* DOKUMENTY
*************************/


%card {
   margin-top: 0;
   overflow: hidden;
   flex-grow: 1;

   img {
      float: none;
      display: table;
      margin: 0;
      width: 100%;
   }

   .dok-datum {
      margin-bottom: 6px;
   }

   .dok-upoutavka {
      display: block;
      overflow: hidden;
      margin: 0;
      
      img {
         margin-bottom: 10px;
      }
   }

   .dok-nazev {
      display: block;
   }

   div {
      margin-top: 8px;
   }
}
