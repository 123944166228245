
/******************
* MODULY
******************/

#uvodtitul {
	overflow: hidden;
	margin: 0 0 30px 0;
}

#upozorneni {
	padding: 15px;
	background-color: pink;

	&,
	h2.cvi,
	#{selector-link()} {
		color: #444444;
	}

	h2.cvi,
	p {
		display: inline-block;
	}
}

#kalendarAkci {
	.editor {
		margin-top: 40px;
		margin-bottom: 40px;
	}
}

#obalmbannery{
	min-width: 320px;
	background: #ffffff;
}

#mbannery {
	padding: 30px 0;
	color: #000000;
	margin: 0;

	.editor {
		text-align: center;

		@include link-all {
			color: #000000 !important;
		}
	}
	
	li { 
		margin-top: 0;
		margin-bottom: 0;
	}
}

#jmeniny {
	padding: 6px 12px 2px 15px;
	text-align: left;
	
	* {
		display: inline;
	}
}

#kontakt {
	address {
		display: block;
		font-style: normal;
		margin: 0 0 25px 0;
		line-height: ( 23 / 15 );
	}

	br {
		line-height: .5;
	}
	
	strong {
		font-weight: bold;
	}

	.kontprvni {
		font-weight: normal;
	}
}

#anketa {
	p {
		padding: 0;
		margin: 0 0 1em 0;
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 8px 0 5px 0;

		li {
			margin-bottom: 8px;
		}
	}

	.hlas {
		margin-top: 3px;
		z-index: 1;
		
		div {
			height: 12px;
			background-color: $color-main;
		}
	}
}

#pocitadlo {
	margin: 15px 0 0 0;
	text-align: center;
	padding-bottom: 15px;

	ul.inline {
		padding-left: 0;
		display: inline;

		li span {
			margin: 0 0 0 2px;
		}
	}

	@include bp("< 600px"){
		li {
			display:block!important;
	
			+ li::before{
				content: "";
				margin:0;
			}	
		}
	}
}

