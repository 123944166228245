
/**************************
* OBECNÉ
**************************/

html {
	touch-action: manipulation;
}

html,
body {
	background-color: #ffffff;
}

body {
	@extend %reset;
	text-align: center;
}

@include selection {
	background-color: $color-main;
	color: #ffffff;
}

a:not([href^="#"]):not([href*="javascript:void"]):not(.patalogo):not(.expandable):not(.menu-link):not(.mouse-focus):focus {
	border-radius: 3px;
	box-shadow: 0 0 0 2000px $link-focus-bg inset, 0 0 0 3px $link-focus-bg !important;
	color: $link-focus-text !important;
	outline: 0;
}

img {
	color: inherit;
}


/*************************
* HLAVNÍ OBSAH
*************************/

.section {
	padding: {
		top: 3em;
		bottom: 3em;
	}

	&.-content {
		background-color: $color-content;
	}
	
	&.-info {
		h2.cvi {
			padding-left: 30px;
		}

		.ui {
			@extend %reset-list;
			display: flex;
			flex-wrap: wrap;

			li {
				@include grid(500px 2 2%, 700px 3, 900px 4, 1100px 5);
				margin-top: 0;
				margin-bottom: 25px;

				strong:first-child {
					a {
						font-size: rem(18px);
					}

					img {
						display: table;
						clear: both;
						width: 100%;
						height: auto;
						float: none;
						margin-bottom: 10px;
					}
				}
			}
		}
	}
}

.centrovany {
	@extend %sf;
	width: 94%;
	max-width: $max-width;
	margin: 0 auto;
	text-align: left;
}
 
#stred,
#vpravo,
#vlevo {
	@extend %border-box;
	width: 100%;
	margin-bottom: 30px;
}

#vpravo,
#vlevo {
	word-wrap: break-word;
}

#stranka #stred {
	padding: 15px 4% 40px 4%;
}


/*************************************
* FLEX LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/

@include bp("> 800px") {
	#stranka #telo {
		display: flex;
		justify-content: space-between;
		padding: 30px 0;
	}

	#stred {
		width: 100%; //kvůli IE11
		order: 1;
		background-color: #ffffff;
	}

	#vlevo {
		width: ratio($max-width, 430px);
		flex-shrink: 0;
		border-right: 1px $color-dark solid;
	}
}

@include bp("> 1200px") {
	#stranka #telo {
		padding-top: 50px;
	}
}

@include bp("> 1600px") {
	#stranka #telo {
		padding-top: 80px;
	}
}


/************************
* PRVKY STRÁNKY
************************/

.obalcesta {
	background-color: #393939;
}

.cesta {
	text-align: right;
	font-size: rem(15px);
	padding: 26px 0 28px 0;
	color: #ffffff;
	position: relative;

	strong {
		text-transform: uppercase;
		font-weight: normal;
	}

	@include link {
		color: #ffffff;
	}
}

.box {
	padding: 0 15px 10px 15px;
	background: #cccccc;
	margin: 0 0 15px 0;
}

.dalsi {
	font-size: rem(26px);
	margin: 15px 0 0 0; 
	padding: 5px 0 0 0;
	text-align: center;
	font-family: $font;
	font-weight: 600;

	a {	
		padding: 14px 30px;
		background-color: #707070;
		display: inline-flex;
		align-items: center;
		justify-content: flex-start;

		&::before {
			content: url(images/arrow_d.svg);
			display: inline-block;
			width: 25px;
			height: 25px;
			margin-right: 12px;
		}

		&::after {
			content: " \2026";
		}
	}

	@include link {
		color: #ffffff;
		text-decoration: none;
	}

	@include link-over {
		text-decoration: underline;
	} 

	@include bp("> 800px") {	 
		text-align: left;
	}
}

.kontdalsi {
	margin-top: 15px;
	text-align: left;

	a {	
		display: inline-block;
		font-weight: 500;
		font-size: rem(17px);
		padding: 12px 30px;
		background-color: #B02F2F;
	}

	@include link {
		color: #ffffff;
		text-decoration: none;
	}

	@include link-over {
		text-decoration: underline;
	}
}


/************************
* ZÁPATÍ
************************/

#nadpatou {
	background: $color-dark;
	font-size: 15px;

	&,
	h2.cvi,
	h3.cvi,
	#{selector-link()} {
		color: #ffffff;
	}

	h2.cvi {
		font-size: rem(26px);
		font-weight: normal;
	}

	.npbox {
		@include grid(900px 3 4%);
		margin-bottom: 20px;
	}

	.dalsi {
		text-align: left;
	}
}


#obalpata {
	background-color: $footer-background;
	min-width: 320px;
}

#pata {
	text-align: center;
	padding: 22px 16px 28px;
	color: $footer-text; 
	box-sizing: border-box;
	font-size: rem(14px);

	.kv {
		padding: 20px 0;
		text-align: center;

		img {
			margin: 0 6px 15px;
		}
	}

	* {
		line-height: 2.2;
		border: 0;
	}

	li + li::before{
		content: "\|";
		margin: 0 8px 0 0;
	}

	.partWebmaster1::before{
		content: "\|";
		margin: 0 10px 0 0;
	}

	@include link {
		color: $footer-link;
		text-decoration: underline;
	}

	@include link-over {
		text-decoration: none;
	}
	
	.patalogo {
		padding: 1px 6px;
		margin-right: 11px;
	}

	#pocitadlo{
		margin: 0!important;
		padding-bottom: 0!important;
	}

	.webmaster,
	.inline {
		display: inline-block;
		margin: 0;
		padding: 0 0 0 3px;

		li {
			padding: 0 5px;

			a {
				margin: 0 0 0 1px;
			}
		}
	}

	.webmaster {
		display: block;

		#stranka &{
				margin-top: 2px;
			}

		.partWebmaster {
			display: inline-block;
			margin-left: 5px;
		}
	}

	ul.inline li {
		display: inline-block;
	}
}

@include bp("< 500px"){
	.partWebmaster1 {
		display: block!important;
		margin-top: 2px;

		&::before{
			content: ""!important;
			margin:0!important;
		}	
	}	
}

#unpobtekane {
	margin-left: 0 !important;
}


@if is-bit-set($web_bitmask, $BIT_SKRYT_KONTEXT) {
	.zobrazeno.kontext2 {
		display: none;
	}
}

@if is-bit-set($web_bitmask, $BIT_SKRYT_ZODPOVIDA) {
	.popis.dpopis {
		display: none;
	}
}