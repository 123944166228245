
/*****************************************
* MIXINY A PROMĚNNÉ PRO TABULKOVÉ MENU
*****************************************/

$menu-spacing: 0;
$menu-colors: $color-obor1 $color-obor2 $color-obor3;

/****************************************/


/******************************
* HORIZONTÁLNÍ MENU A VÝBĚRY
******************************/

#obalmenu1 {
	position: relative;
	z-index: 15;
}

#menu1 {
	ul {
		@extend %reset-list;
		margin-bottom: 24px;
	}

	li {
		font-size: rem(26px);
		line-height: 1.1;
		margin-top: 0;
		margin-bottom: 0;
		background-color: #ffffff;

		&.akt {
			border: 5px #000 solid;
		}

		a {
			display: flex;
			font-family: $font;
			flex-direction: column;

			.menunazev {
				padding: 32px 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				
				&::after {
					content: url(images/menu_fw.png);
					display: inline-block;
					width: 17px;
					height: 16px;
					flex-grow: 0;
					margin-left: 14px;
				}
			}

			.menupopis {
				padding: ratio(400px, 220px) 30px 32px 30px;
				font-size: rem(18px);
				background: {
					repeat: no-repeat;
					position: 50% 0;
					size: 100% auto;
				}
			}
		}

		@include link {
			text-decoration: none;
			color: #343434;
		}

		@include link-over {
			//text-decoration: underline;
		}

		@include link-all("#osmakth") {
			cursor: text;
			text-decoration: none;
		}
	}
}

@include bp("<= 500px") {
	#menu1 {
		li {
			margin-bottom: 15px;
			background-color: #e4e4e4;
		}
	}
}

@include bp("> 500px", "<= 800px") {
	#menu1 {
		ul {
			display: flex;
			flex-wrap: wrap;
		}

		li {
			box-sizing: border-box;
			@include grid-column(2, 2%);
			float: left;
			background-color: #e4e4e4;
			margin-bottom: 15px;
		}
	}
}

@include bp("> 800px") {
	#menu1 ul {
		display: flex;
		margin-left: -$menu-spacing;
		margin-right: -$menu-spacing;
	}

	#menu1 li {
		margin-top: -3.55em;
		flex: 1 1 (100% / 4);
		box-sizing: border-box;
		margin-left: $menu-spacing;
		margin-right: $menu-spacing;

		+ li {
			border-left: 1px #707070 solid;
		}
	}
}

@for $i from 1 through length($menu-colors) {
	#menu1 #p#{$i} .menupopis {
		border-top: 11px nth($menu-colors, $i) solid;
		background-image: url(images/obor#{$i}.jpg);
	}
}


.menu-hlava {
	text-align: center;
	padding: 20px 0;

	@include bp("< 1024px") {
		order: 3;
		width: 100%;
	}

	ul {
		@extend %reset-list;

		li {
			display: inline-block;
			margin: 0 .75em;

			> strong:first-child {
				font-size: rem(18px);
			}

			a {
				
			}

			@include link {
				color: #343434;
				text-decoration: none;
			}
			
			@include link-over {
				text-decoration: underline;
			}
		} 
	}
}


@if $bool_dlazdice == false {

	/***********************
	*** ROZBALOVACÍ MENU ***
	***********************/

	#menu ul {
		@extend %reset-list;
	}

	.menu-controls,
	#menu-start {
		display: none;
	}

	.nadmenu,
	#nadmenu {
		display: none;
	}


	/**********************
	* MOBILNÍ MENU
	**********************/

	#menu {
		background-color: $menu-bg;
		padding: 20px;

		> ul {

			a {
				padding: 5px 20px;
				margin-left: -20px;
				display: block;
			}

			@include link {
				color: $menu-link-color;
			}

			@include link-all("#osmakt") {
				background-color: $menu-active-bg;
				color: $menu-active-text;
				text-decoration: none;
			}
		}

		ul {
			padding-left: 20px;
		}
	}


	@include bp("<= #{$menu-tablet-size}") {
		#menu-start {
			display: block;
			clear: both;
			text-align: center;
			margin-bottom: 7px;
			padding: 15px;

			a {
				background: $menu-trigger-bg;
				display: block;
				padding: 15px 0;

				&::before {
					display: inline-block;
					vertical-align: middle;
					margin: -.15em .75em 0 0;
					content: encode-svg('<svg xmlns="http://www.w3.org/2000/svg" width="30" height="22" viewBox="0 0 30 22"><path fill="#{$menu-trigger-text}" fill-rule="evenodd" clip-rule="evenodd" d="M29 22h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1zm0-9h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1zm0-9h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1z"/></svg>');
				}
			}

			@include link {
				color: $menu-trigger-text;
				text-decoration: none;
			}
		}

		.no-scroll {
			height: 100%;
			touch-action: none;
			overflow: hidden;
			pointer-events: none;
			-webkit-overflow-scrolling: auto;

			body {
				height: 100%;
				touch-action: none;
				overflow: hidden;

				// > {
				// 	div {
				// 		height: 0;
				// 		touch-action: none;
				// 		overflow: hidden;
				// 	}
				// }
			}
		}

		#menu.menu-initialised {
			pointer-events: auto;
			box-sizing: border-box;
			z-index: 200;
			position: fixed;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			height: 100%;
			max-width: 400px;
			height: 100vh;
			padding: 70px 20px 20px 0;
			transition: transform .3s ease-out;
			-webkit-transform: translateX(-100%);
			transform: translateX(-100%);
			opacity: 0;
			-webkit-transition: -webkit-transform .3s ease-out;

			[data-env="Safari"] & {
				max-width: 100%;
			}

			&.menu-active {
				-webkit-transform: translateX(0);
				transform: translateX(0);
				opacity: 1;
			}

			> ul {
				// position: relative;
				box-sizing: border-box;
				width: 100%;
				height: 100%;
				margin: 0;
				padding: 0 0 0 20px;
				overflow-y: auto;
				-webkit-overflow-scrolling: touch;
				z-index: 101;

				> li {
					margin-bottom: 15px;

					&:last-child {
						margin-bottom: 80px;
					}
				}
				// ul {
				// 	padding: 0 0 0 20px;
				// }
			}

			ul {
				list-style-type: none;
			}

			.menu-controls {
				position: fixed;
				left: 0;
				top: 0;
				right: 0;
				display: block;
				height: 50px;
				z-index: 100;
				//width: 100%;
				background-color: $menu-topbar-bg;
				color: $menu-topbar-text;
				//display: table;

				.menu-control-back, 
				.menu-control-title {
					display: block;
				}

				.menu-control-back {
					position: absolute;
					width: 50px;
					height: 50px;
					right: 0;
					top: 0;
					background: {
						image: encode-svg('<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path fill="#{$menu-topbar-text}" fill-rule="evenodd" d="M13.569 10.994l8.162 8.165c.355.358.355.936 0 1.294l-1.28 1.279c-.357.358-.935.358-1.293 0l-8.16-8.166-8.162 8.166c-.357.358-.935.358-1.292 0l-1.278-1.279c-.357-.358-.357-.936 0-1.294l8.162-8.165-8.152-8.154c-.357-.357-.357-.935 0-1.292l1.279-1.28c.356-.357.935-.357 1.292 0l8.151 8.154 8.152-8.154c.357-.357.935-.357 1.293 0l1.276 1.28c.359.357.359.935 0 1.292l-8.15 8.154z"/></svg>');
						repeat: no-repeat;
						position: 50% 50%;
						size: 22px 22px;
					}
				}

				.menu-control-title {
					padding: 0 20px;
					line-height: 2.2;
					border-right: 70px transparent solid;
					white-space: nowrap;
					font-size: rem(20px);
					-ms-text-overflow: ellipsis;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}

			.menu-indicator {
				display: none;
			}
		}
	}


	/***********************
	* DESKTOPOVÉ MENU
	***********************/

	@include bp("> #{$menu-tablet-size}") {
		.nadmenu {
			display: block;
		}

		#nadmenu {
			font-size: rem(14px);
			display: none;
			font-weight: normal;
			padding: 0;
			white-space: nowrap;
		}

		#menu {
			background-color: $menu-bg;
			padding: 0 0 50px 0;

			h2.cvi {
				background-color: $color-main;
				padding: 10px 25px 10px 40px;
				font-size: rem(28px);
				color: #000000;
				font-weight: normal;
			}

			.menu-item-collapsed > ul {
				display: none;
			}

			.menu-item-expanded {
				> a .menu-indicator {
					transform: rotate(225deg);
				}

				> ul {
					display: block;
				}
			}

			.menu {
				font-size: rem(18px);
				font-family: $font;
				line-height: 1.2;
				padding: 28px 40px 5px 40px;
				text-transform: uppercase;

				ul {
					text-transform: none;
				}

				@include link {
					color: $menu-link-color;
					text-decoration: none;
				}

				> li + li {
					border-top: 1px #c7c6c5 solid; 
				}

				@include link("ul a") {

				}

				@include link-over("ul a") {
					text-decoration: underline;
				}

				@include link-all("#osmakt") {
					font-weight: bold;
					background-color: transparent;
					text-decoration: none;
					cursor: default;
					border: 0;
				}

				a {
					padding: 13px 0 13px 0;
					display: block;
					position: relative;
					z-index: 10;
					margin-left: 0;
					text-decoration: none;
				}

				.menu-indicator {
					width: 16px;
					height: 16px;
					overflow: hidden;
					position: absolute;
					top: .85em;
					left: -27px;
					z-index: 10;
					-webkit-transition: -webkit-transform .25s;
					transition: -ms-transform .25s;
					transition: transform .25s;

					img {
						margin: 0;
						cursor: pointer;
						font-size: rem(14px);
					}
				}

				ul {
					font-size: rem(16px);
					font-family: $sans-serif;
					margin: 0 0 0 -40px;
					padding: 2px 0 12px 0;

					a {
						padding: 1px 15px 0 40px;
						line-height: 1.5;
					}

					.menu-indicator {
						left: 13px;
						top: .23em;
					}

					ul {
						margin: 0;
						padding: 0 0 0 24px;
						position: static;
					}
				}
			}

			li {
				margin: 0;
				padding: 0;
				position: relative;
				display: block;

				li {
					border: 0;
					font-weight: normal;
				}

				// &.akt {
				// 	font-weight: bold;
				// 	text-decoration: none;
				// }
			}
		}
	}

} @else if $bool_dlazdice == true {

	/**********************
	*** DLAŽDICOVÉ MENU ***
	**********************/

	#menu ul {
		@extend %reset-list;
	}

	.nadmenu {
		display: block;
		font-weight: normal;
		padding: 33px 20px 12px 88px;
		font-family: $font;
		font-size: rem(26px);
		font-weight: 500;
		line-height: 1.1;
		color: #B02F2F;
		font-weight: bold;
		
		@include link {
			font-family: $font;
			color: #B02F2F;
			text-decoration: none;
		}

		@include link-over {
			text-decoration: underline;
		}

		a {
			position: relative;	
			
			&::before {
				content: url(images/arr_back.svg);
				width: 25px;
				height: 25px;
				position: absolute;
				top: .15em;
				left: -86px;
			}
		}
	}

	/**********************
	* MOBILNÍ MENU
	**********************/

	#menu {
		background-color: $menu-bg;
		padding: 20px;

		ul {
			padding-left: 20px;

			a {
				padding: 5px 20px;
				margin-left: -20px;
				display: block;
			}

			@include link {
				color: $menu-link-color;
			}

			@include link-all("#osmakt") {
				background-color: $menu-active-bg;
				color: $menu-active-text;
				text-decoration: none;
			}
		}
	}


	/***********************
	* DESKTOPOVÉ MENU
	***********************/

	@include bp("> #{$menu-tablet-size}") {
		.nadmenu {
			display: block;
		}

		#nadmenu {
			font-size: rem(14px);
			display: none;
			font-weight: normal;
			padding: 0;
			white-space: nowrap;
		}

		#menu {
			background-color: $menu-bg;
			padding: 0 0 50px 0;

			ul { 
				font-size: rem(20px);
				font-family: $font;
				line-height: 1.2;
				padding: 28px 0 5px 0;

				@include link {
					color: $menu-link-color;
					text-decoration: none;
				}

				@include link(".aktodk") {
					color: #232323;
					text-decoration: none;
				}

				@include link-over {
					text-decoration: underline;
				}

				// > li + li {
				// 	border-top: 1px #c7c6c5 solid; 
				// }

				@include link-all("#osmakth") {
					background-color: transparent;
					text-decoration: none;
					cursor: default;
					color: #232323;
					border: 0;
				}

				a {
					padding: 10px 0 10px 0;
					display: block;
					position: relative;
					z-index: 10;
					margin-left: 0;
					text-decoration: none;
				}
			}

			li {
				margin: 0;
				padding: 0 20px 0 88px;
				position: relative;
				display: block;

				&.akt {
					background-color: #ffffff;
				}
			}
		}
	}

}