@charset "UTF-8";
/*!
  Author: WEBHOUSE, s. r. o.
  Version: 1.2
*/
/** PROMĚNNÉ PRO NASTAVENÍ ECCO **/
/*********************************/
/*! ECCO SASS knihovna
    @version: 1.0.4 */
/*****************************************
* ECCO - GLOBAL VARIABLES
*****************************************/
/*****************************************
* ECCO - FUNCTIONS
*****************************************/
/*****************************************
* ECCO - MIXINS
*****************************************/
/*****************************************
* ECCO - PLACEHOLDERS
*****************************************/
#stranka #kalakci .mesice, #titul .zalozky li, #keskryti, .fullscreen-search form, #hledani .btn, .obrodkazy li, body, #menu ul, .menu-hlava ul, #menu1 ul, .bezjs ul, .zalozky ul, .vybrane-odkazy .ui, .titulodkazy .ui, .obrodkazy .ui, .dok ul, #lista ul, .section.-info .ui {
  margin: 0;
  padding: 0;
}

#menu ul, .menu-hlava ul, #menu1 ul, .bezjs ul, .zalozky ul, .vybrane-odkazy .ui, .titulodkazy .ui, .obrodkazy .ui, .dok ul, #lista ul, .section.-info .ui {
  list-style-type: none;
}

.fullscreen-search .fullscreen-search-input, .fullscreen-search .fullscreen-search-button {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent none;
  box-shadow: none;
}
.fullscreen-search .fullscreen-search-input:focus, .fullscreen-search .fullscreen-search-button:focus {
  box-shadow: none;
}

.titulformular label.label-skryty::after, #nazev a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#kalakci .calcells td .desc {
  position: absolute;
  left: -9999em;
  top: 0;
  width: 0;
  height: 0;
  line-height: 0;
  overflow: hidden;
  outline: 0;
}

.odkazy .ui li li li::before, .aktuality2 li.bezobr li li::before, .utvary .ui li.o li li::before, .kategorie .ui li li li::before, .utvary .ui li li li::before, .galerie .ui li li li::before, #stred,
#vpravo,
#vlevo {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.zalozky ul::after, #lista::after, #hlava::after, .centrovany::after {
  content: "";
  display: table;
  font-size: 0;
  clear: both;
}

/*****************************************
* ECCO - FLEXBOX
*****************************************/
/***********************************
/* HODNOTY BITŮ PRO NASTAVENÍ WEBU 
***********************************/
/*************************
* GLOBÁLNÍ PROMĚNNÉ
*************************/
/************************/
/*************************
* PROMĚNNÉ KOMPONENT
*************************/
/** TYPOGRAFIE **/
/** FORMULÁŘE **/
/** TABULKY **/
/** SEZNAMY **/
/** AKCE **/
/** KALENDÁŘE **/
/** Hodnoty je lepší zadávat v em **/
/** MENU **/
/** ZÁLOŽKY **/
/** PATA **/
/************************/
/***********************************************************
* PROMĚNNÉ BREAKPOINTŮ PRO SNADNĚJŠÍ ZADÁVÁNÍ MIXINU "BP"
***********************************************************/
/**********************************************************/
@font-face {
  font-family: "RobotoWeb";
  font-weight: normal;
  font-style: normal;
  src: url("/ASPINCLUDE/vismoWeb5/html/fonty/roboto-regular-webfont.woff2") format("woff2"), url("/ASPINCLUDE/vismoWeb5/html/fonty/roboto-regular-webfont.woff") format("woff"), url("/ASPINCLUDE/vismoWeb5/html/fonty/roboto-regular-webfont.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "RobotoWeb";
  font-weight: 100;
  font-style: normal;
  src: url("/ASPINCLUDE/vismoWeb5/html/fonty/roboto-thin-webfont.woff2") format("woff2"), url("/ASPINCLUDE/vismoWeb5/html/fonty/roboto-thin-webfont.woff") format("woff"), url("/ASPINCLUDE/vismoWeb5/html/fonty/roboto-thin-webfont.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "RobotoWeb";
  font-weight: 300;
  font-style: normal;
  src: url("/ASPINCLUDE/vismoWeb5/html/fonty/roboto-light-webfont.woff2") format("woff2"), url("/ASPINCLUDE/vismoWeb5/html/fonty/roboto-light-webfont.woff") format("woff"), url("/ASPINCLUDE/vismoWeb5/html/fonty/roboto-light-webfont.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "RobotoWeb";
  font-weight: 500;
  font-style: normal;
  src: url("/ASPINCLUDE/vismoWeb5/html/fonty/roboto-medium-webfont.woff2") format("woff2"), url("/ASPINCLUDE/vismoWeb5/html/fonty/roboto-medium-webfont.woff") format("woff"), url("/ASPINCLUDE/vismoWeb5/html/fonty/roboto-medium-webfont.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "RobotoWeb";
  font-weight: bold;
  font-style: normal;
  src: url("/ASPINCLUDE/vismoWeb5/html/fonty/roboto-bold-webfont.woff2") format("woff2"), url("/ASPINCLUDE/vismoWeb5/html/fonty/roboto-bold-webfont.woff") format("woff"), url("/ASPINCLUDE/vismoWeb5/html/fonty/roboto-bold-webfont.ttf") format("truetype");
  font-display: swap;
}
html {
  line-height: 1.375;
  font-family: RobotoWeb;
  font-size: 18px;
}
@media screen and (min-width: 601px) {
  html {
    font-size: 16px;
  }
}

body.editor {
  background-color: #ffffff;
}

.editor h3 {
  font-weight: normal;
  margin: 10px 0;
  padding: 0;
  font-family: RobotoWeb;
  line-height: 1.2;
}

body,
.editor,
body.editor,
body.editor td,
body.editor th {
  font-size: 1em;
}

body,
.automat {
  color: #444444;
}

a,
a:link,
a:visited {
  color: #232323;
  text-decoration: underline;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

hr,
hr.oddelovac {
  color: #dddddd;
  background-color: #dddddd;
}

body,
input,
button,
textarea,
select {
  font-family: RobotoWeb;
}

.zvyrazneni,
.vystraha,
.strlistovani strong {
  color: #BD013E;
  background-color: #ffffff;
  padding: 0 3px;
}

.vystraha {
  border-radius: 3px;
}

.zvyrazneni2 {
  background-color: #303030;
  color: #ffffff;
  padding: 0 2px;
}

strong.zprava {
  font-size: 1.1em;
}

/*******************
* NADPISY
*******************/
h1.cvi {
  font-size: 0.9375rem;
  padding: 0;
  line-height: 1.1;
  margin: 0;
  font-family: RobotoWeb;
  font-weight: normal;
}
@media screen and (min-width: 601px) {
  h1.cvi {
    margin-right: 20px;
  }
}
h1.cvi strong {
  font-size: 1.875rem;
  font-weight: normal;
  display: block;
}

h2.cvi,
h2.cist,
h3.cvi,
h3.ud,
#prohlaseni h3,
h4 {
  font-family: RobotoWeb;
}

h2.cvi,
.poz h2.cist {
  font-size: 2.5rem;
  color: #636363;
  font-weight: bold;
  margin: 0 0 22px 0;
  padding: 8px 0;
  line-height: 1.2;
}

#zahlavi h2.cvi {
  margin: 3px 0 30px 0;
  padding: 0;
}

#zahlavi h2.cvi,
#zalozkynadpis h2.cvi {
  font-size: 2.5rem;
  color: #636363;
}

.editor h3, h3.cvi, h3.ud, #prohlaseni h3 {
  font-size: 2.1875rem;
  font-weight: normal;
  margin: 25px 0 5px 0;
  padding: 8px 20px 4px 0;
  line-height: 1.2;
}

h4 {
  font-size: 1.875rem;
  line-height: 1.2;
}

h4.urd-list {
  font-size: 1.5625rem;
  padding-top: 0.75em;
  float: none;
  border-top: 3px #cccccc solid;
  padding-right: 7em;
}
h4.urd-list + .udz.urd-list {
  margin-top: -1.6em;
  margin-bottom: 2em;
  float: none;
  text-align: right;
}
@media screen and (max-width: 1023px) {
  h4.urd-list {
    padding-right: 0;
  }
  h4.urd-list + .udz.urd-list {
    margin-top: 1em;
  }
}

h5 {
  font-size: 1.1em;
}

/*************************
* ŠABLONY
*************************/
/*************************
* DOKUMENTY
*************************/
#pozicovani .cards .card {
  margin-top: 0;
  overflow: hidden;
  flex-grow: 1;
}
#pozicovani .cards .card img {
  float: none;
  display: table;
  margin: 0;
  width: 100%;
}
#pozicovani .cards .card .dok-datum {
  margin-bottom: 6px;
}
#pozicovani .cards .card .dok-upoutavka {
  display: block;
  overflow: hidden;
  margin: 0;
}
#pozicovani .cards .card .dok-upoutavka img {
  margin-bottom: 10px;
}
#pozicovani .cards .card .dok-nazev {
  display: block;
}
#pozicovani .cards .card div {
  margin-top: 8px;
}

/**************************
* OBECNÉ
**************************/
html {
  touch-action: manipulation;
}

html,
body {
  background-color: #ffffff;
}

body {
  text-align: center;
}

::-moz-selection {
  background-color: #228be6;
  color: #ffffff;
}

::selection {
  background-color: #228be6;
  color: #ffffff;
}

a:not([href^="#"]):not([href*="javascript:void"]):not(.patalogo):not(.expandable):not(.menu-link):not(.mouse-focus):focus {
  border-radius: 3px;
  box-shadow: 0 0 0 2000px #303030 inset, 0 0 0 3px #303030 !important;
  color: #ffffff !important;
  outline: 0;
}

img {
  color: inherit;
}

/*************************
* HLAVNÍ OBSAH
*************************/
.section {
  padding-top: 3em;
  padding-bottom: 3em;
}
.section.-content {
  background-color: #E4E4E4;
}
.section.-info h2.cvi {
  padding-left: 30px;
}
.section.-info .ui {
  display: flex;
  flex-wrap: wrap;
}
.section.-info .ui li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  margin-top: 0;
  margin-bottom: 25px;
}
@media screen and (max-width: 499px) {
  .section.-info .ui li {
    width: 100%;
  }
}
@media screen and (min-width: 500px) and (max-width: 699px) {
  .section.-info .ui li {
    width: 49%;
    margin-left: 2%;
  }
  .section.-info .ui li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 700px) and (max-width: 899px) {
  .section.-info .ui li {
    width: 32%;
    margin-left: 2%;
  }
  .section.-info .ui li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 900px) and (max-width: 1099px) {
  .section.-info .ui li {
    width: 23.5%;
    margin-left: 2%;
  }
  .section.-info .ui li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1100px) {
  .section.-info .ui li {
    width: 18.4%;
    margin-left: 2%;
  }
  .section.-info .ui li:nth-of-type(5n+1) {
    margin-left: 0;
    clear: left;
  }
}
.section.-info .ui li strong:first-child a {
  font-size: 1.125rem;
}
.section.-info .ui li strong:first-child img {
  display: table;
  clear: both;
  width: 100%;
  height: auto;
  float: none;
  margin-bottom: 10px;
}

.centrovany {
  width: 94%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
}

#stred,
#vpravo,
#vlevo {
  width: 100%;
  margin-bottom: 30px;
}

#vpravo,
#vlevo {
  word-wrap: break-word;
}

#stranka #stred {
  padding: 15px 4% 40px 4%;
}

/*************************************
* FLEX LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/
@media screen and (min-width: 801px) {
  #stranka #telo {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
  }
  #stred {
    width: 100%;
    order: 1;
    background-color: #ffffff;
  }
  #vlevo {
    width: 35.8333333333%;
    flex-shrink: 0;
    border-right: 1px #393939 solid;
  }
}
@media screen and (min-width: 1201px) {
  #stranka #telo {
    padding-top: 50px;
  }
}
@media screen and (min-width: 1601px) {
  #stranka #telo {
    padding-top: 80px;
  }
}
/************************
* PRVKY STRÁNKY
************************/
.obalcesta {
  background-color: #393939;
}

.cesta {
  text-align: right;
  font-size: 0.9375rem;
  padding: 26px 0 28px 0;
  color: #ffffff;
  position: relative;
}
.cesta strong {
  text-transform: uppercase;
  font-weight: normal;
}
.cesta a,
.cesta a:link,
.cesta a:visited {
  color: #ffffff;
}

.box {
  padding: 0 15px 10px 15px;
  background: #cccccc;
  margin: 0 0 15px 0;
}

.dalsi {
  font-size: 1.625rem;
  margin: 15px 0 0 0;
  padding: 5px 0 0 0;
  text-align: center;
  font-family: RobotoWeb;
  font-weight: 600;
}
.dalsi a {
  padding: 14px 30px;
  background-color: #707070;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}
.dalsi a::before {
  content: url(images/arrow_d.svg);
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 12px;
}
.dalsi a::after {
  content: " …";
}
.dalsi a,
.dalsi a:link,
.dalsi a:visited {
  color: #ffffff;
  text-decoration: none;
}
.dalsi a:hover,
.dalsi a:focus,
.dalsi a:active {
  text-decoration: underline;
}
@media screen and (min-width: 801px) {
  .dalsi {
    text-align: left;
  }
}

.kontdalsi {
  margin-top: 15px;
  text-align: left;
}
.kontdalsi a {
  display: inline-block;
  font-weight: 500;
  font-size: 1.0625rem;
  padding: 12px 30px;
  background-color: #B02F2F;
}
.kontdalsi a,
.kontdalsi a:link,
.kontdalsi a:visited {
  color: #ffffff;
  text-decoration: none;
}
.kontdalsi a:hover,
.kontdalsi a:focus,
.kontdalsi a:active {
  text-decoration: underline;
}

/************************
* ZÁPATÍ
************************/
#nadpatou {
  background: #393939;
  font-size: 15px;
}
#nadpatou,
#nadpatou h2.cvi,
#nadpatou h3.cvi,
#nadpatou a, #nadpatou a:link, #nadpatou a:visited {
  color: #ffffff;
}
#nadpatou h2.cvi {
  font-size: 1.625rem;
  font-weight: normal;
}
#nadpatou .npbox {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  margin-bottom: 20px;
}
@media screen and (max-width: 899px) {
  #nadpatou .npbox {
    width: 100%;
  }
}
@media screen and (min-width: 900px) {
  #nadpatou .npbox {
    width: 30.6666666667%;
    margin-left: 4%;
  }
  #nadpatou .npbox:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
#nadpatou .dalsi {
  text-align: left;
}

#obalpata {
  background-color: #000000;
  min-width: 320px;
}

#pata {
  text-align: center;
  padding: 22px 16px 28px;
  color: #ffffff;
  box-sizing: border-box;
  font-size: 0.875rem;
}
#pata .kv {
  padding: 20px 0;
  text-align: center;
}
#pata .kv img {
  margin: 0 6px 15px;
}
#pata * {
  line-height: 2.2;
  border: 0;
}
#pata li + li::before {
  content: "|";
  margin: 0 8px 0 0;
}
#pata .partWebmaster1::before {
  content: "|";
  margin: 0 10px 0 0;
}
#pata a,
#pata a:link,
#pata a:visited {
  color: #ffffff;
  text-decoration: underline;
}
#pata a:hover,
#pata a:focus,
#pata a:active {
  text-decoration: none;
}
#pata .patalogo {
  padding: 1px 6px;
  margin-right: 11px;
}
#pata #pocitadlo {
  margin: 0 !important;
  padding-bottom: 0 !important;
}
#pata .webmaster,
#pata .inline {
  display: inline-block;
  margin: 0;
  padding: 0 0 0 3px;
}
#pata .webmaster li,
#pata .inline li {
  padding: 0 5px;
}
#pata .webmaster li a,
#pata .inline li a {
  margin: 0 0 0 1px;
}
#pata .webmaster {
  display: block;
}
#stranka #pata .webmaster {
  margin-top: 2px;
}
#pata .webmaster .partWebmaster {
  display: inline-block;
  margin-left: 5px;
}
#pata ul.inline li {
  display: inline-block;
}

@media screen and (max-width: 499px) {
  .partWebmaster1 {
    display: block !important;
    margin-top: 2px;
  }
  .partWebmaster1::before {
    content: "" !important;
    margin: 0 !important;
  }
}
#unpobtekane {
  margin-left: 0 !important;
}

/*************************
* ZÁHLAVÍ
*************************/
/*************************
* HLAVA
*************************/
#obalhlava {
  position: relative;
  z-index: 13;
  background-color: #ffffff;
  color: #000000;
}
#obalhlava a,
#obalhlava a:link,
#obalhlava a:visited {
  color: #343434;
}

#hlava {
  clear: both;
  position: relative;
  z-index: 3;
}
#hlava .centrovany {
  padding-top: 35px;
  padding-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
}
#hlava .centrovany .hlava__doplnek {
  margin-top: 25px;
}
@media screen and (min-width: 600px) {
  #hlava .centrovany {
    justify-content: space-between;
  }
  #hlava .centrovany .hlava__doplnek {
    margin-top: 0;
  }
}
#hlava .centrovany .slogan {
  position: absolute;
  left: 0;
  top: 100%;
  margin: 0;
  padding: 12px 62px;
  transform: translateY(-50%);
  background-color: #1d1d1b;
  color: white;
  font-size: 0.9375rem;
  display: none;
}
@media screen and (min-width: 600px) {
  #hlava .centrovany .slogan {
    display: block;
  }
}
#hlava .centrovany::after {
  content: normal;
}

#znak {
  margin: 0;
}

#nazev {
  text-align: center;
  position: relative;
  margin: 0;
}
@media screen and (max-width: 600px) {
  #nazev {
    margin-left: auto;
    margin-right: auto;
  }
}
#nazev a {
  z-index: 30;
  display: inline;
  text-decoration: none !important;
  outline-width: 0;
  font-size: 0;
}
#nazev a::after {
  content: "";
}
#nazev img {
  font-size: 1rem;
  line-height: 1;
}

@media screen and (min-width: 501px) {
  #nazev {
    text-align: left;
    float: left;
    display: flex;
  }
}
/****************************
* HLAVNÍ OBRÁZEK A BLENDER
****************************/
#obalobrazek {
  display: none;
}

@media screen and (min-width: 601px) {
  #obalobrazek {
    display: block;
    clear: both;
  }
  #obrazek {
    position: relative;
  }
  #administraceobrazky {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
  }
}
#blender {
  height: 0;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  overflow: hidden;
  padding-bottom: 31.25%;
  background: #303030 url(images/blenderImages/0.jpg#upfplqr) no-repeat 50% 50%;
  background-size: cover;
}

.blender__buttons {
  position: absolute;
  z-index: 3;
  right: 1em;
  bottom: 1em;
}

.blender__button {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent none;
  box-shadow: none;
}
.blender__button:focus {
  box-shadow: none;
}

@keyframes blend-in {
  from {
    display: block;
    opacity: 0;
  }
  to {
    display: block;
    opacity: 1;
  }
}
.blender__item {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.blender__item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.blender__item.blender__item--animating {
  animation: blend-in 800ms ease-out;
  animation-fill-mode: forwards;
}

#lista {
  text-align: right;
  padding: 0;
  position: relative;
}
#lista ul {
  position: relative;
}
#lista ul.inline {
  display: inline;
}
#lista li.skip {
  white-space: nowrap;
  margin: 0;
  position: absolute;
  display: block;
  top: 0 !important;
  right: 0 !important;
  max-width: 100%;
  padding: 1px;
  border: 0;
}
#lista li.skip a {
  text-align: center;
  border: 0;
  position: absolute;
  right: 0;
  top: -500px;
  z-index: 0;
  padding: 0 2px;
  background-color: #ffffff;
  color: #000000;
}
#lista li.skip a:focus,
#lista li.skip a:active {
  z-index: 10 !important;
  position: absolute !important;
  right: 10px;
  top: 5px;
}

#jazyky {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: inline-block;
}
#jazyky li::before {
  content: "";
  margin: 0 9px 0 10px;
}
#jazyky li a,
#jazyky li a:link,
#jazyky li a:visited {
  color: #707070;
  text-decoration: underline;
}
#jazyky li a:hover,
#jazyky li a:focus,
#jazyky li a:active {
  text-decoration: none;
}

.pomocne {
  display: inline-flex;
  align-items: center;
  text-align: right;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.pomocne li {
  margin: 0 0 0 1em;
  display: inline-flex;
  align-items: center;
}
.pomocne li.facebook {
  margin-left: 30px;
}
.pomocne a {
  display: inline-block;
}

/*************************
* SEZNAMY
*************************/
/*************************
* MIXINY PRO ODRÁŽKY
*************************/
/************************/
.odkazy .ui li li li::before, .aktuality2 li.bezobr li li::before, .utvary .ui li.o li li::before, .kategorie .ui li li li::before, .utvary .ui li li li::before, .galerie .ui li li li::before {
  position: relative;
  top: -0.08em;
  margin-right: 7px;
  display: inline-block;
  content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='7px' height='7px' viewBox='0 0 7 7'%3E%3Cpath fill='%23BCBCBC' stroke='%23BCBCBC' stroke-miterlimit='10' d='M2.214,0.5h2.572C5.732,0.5,6.5,1.268,6.5,2.214v2.572C6.5,5.732,5.732,6.5,4.786,6.5H2.214C1.268,6.5,0.5,5.732,0.5,4.786V2.214C0.5,1.268,1.268,0.5,2.214,0.5z'/%3E%3C/svg%3E");
  vertical-align: middle;
  line-height: 0;
  overflow: hidden;
}
li.u, li.typsouboru {
  list-style-type: none !important;
}
li.u::before, li.typsouboru::before {
  content: normal !important;
}
.odkazy ol li::before::before {
  content: normal !important;
}

dl dt {
  font-size: 1.1875rem;
  color: #343434;
  line-height: 1.1;
}

dl.kontakty .utvary {
  padding-top: 4px;
}

.ui li > strong:first-child {
  font-family: RobotoWeb;
  font-size: 1.4375rem;
}
.ui li > strong:first-child a,
.ui li > strong:first-child a:link,
.ui li > strong:first-child a:visited {
  color: #343434;
}
.utvary .ui li > strong:first-child, .souvisejiciodkazy .ui li > strong:first-child {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: bold;
}
.utvary .ui li > strong:first-child a,
.utvary .ui li > strong:first-child a:link,
.utvary .ui li > strong:first-child a:visited, .souvisejiciodkazy .ui li > strong:first-child a,
.souvisejiciodkazy .ui li > strong:first-child a:link,
.souvisejiciodkazy .ui li > strong:first-child a:visited {
  color: #232323;
}
.ui li > strong:first-child img {
  font-size: 0.875rem;
}
.utvary .ui li li > strong:first-child, .bodkazy .ui li li > strong:first-child, .souvisejiciodkazy .ui li li > strong:first-child {
  font-weight: normal;
}
.ui ul li > strong:first-child {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
}
.ui ul li > strong:first-child a,
.ui ul li > strong:first-child a:link,
.ui ul li > strong:first-child a:visited {
  color: #232323;
}
.ui li {
  clear: none;
  padding: 0;
  margin: 0 0 5px 0;
}
.ui li strong {
  font-weight: normal;
  line-height: 1.2;
}
dl.kontakty .utvary .ui li strong + div, #stromutvaru .utvary .ui li strong + div, .odkazy .ui li strong + div {
  padding: 0;
  color: #666666;
  line-height: 1.4;
  font-style: italic;
}
dl.kontakty .utvary .ui li strong + div {
  margin-bottom: 10px;
}
.ui li .ui {
  padding-top: 0;
  padding-bottom: 10px;
}
.ui li .ui .ui {
  padding-bottom: 0;
}
.ui li .ui .ui .ui {
  padding-left: 18px;
}
.ui li li {
  margin: 5px 0;
}
.ui li li::after {
  content: normal;
}
.ui ol li {
  padding-left: 0;
}
.ui div {
  font-weight: normal;
  margin-bottom: 2px;
  margin-top: 6px;
  padding-top: 0;
  padding-bottom: 0;
}

dl.kontakty .utvary ul.ui {
  margin-left: 0;
  margin-bottom: 15px;
}
dl.kontakty .utvary li strong {
  font-size: 0.9375rem;
  font-family: Arial, Helvetica, sans-serif;
}
dl.kontakty .utvary li strong a,
dl.kontakty .utvary li strong a:link,
dl.kontakty .utvary li strong a:visited {
  color: #232323;
}

/**********************************
*	POVINNĚ ZVEŘEJŇOVANÉ INFORMACE
**********************************/
#isvs .hodnota li::before {
  content: normal !important;
}

/*********************
* VÝPISY DOKUMENTŮ
*********************/
.dok li {
  display: block;
  margin: 20px 0;
  padding: 0;
}
.dok li:first-child {
  margin-top: 8px;
}
.dok .n5-akce-typ,
.dok .dok-doplnek,
.dok .ktg,
.dok strong + span,
.dok .vd-priloha {
  font-size: 0.9375rem;
  color: #444444;
}
.dok .n5-akce-typ a,
.dok .n5-akce-typ a:link,
.dok .n5-akce-typ a:visited,
.dok .dok-doplnek a,
.dok .dok-doplnek a:link,
.dok .dok-doplnek a:visited,
.dok .ktg a,
.dok .ktg a:link,
.dok .ktg a:visited,
.dok strong + span a,
.dok strong + span a:link,
.dok strong + span a:visited,
.dok .vd-priloha a,
.dok .vd-priloha a:link,
.dok .vd-priloha a:visited {
  color: #444444;
}
.dok .vd-priloha {
  font-family: Arial, Helvetica, sans-serif;
  white-space: nowrap;
}
.dok strong {
  font-weight: normal;
}
.dok strong img {
  margin: 3px 20px 6px 0;
  float: left;
}
.dok strong a {
  font-size: 1.625rem;
  font-family: RobotoWeb;
  color: #343434;
  line-height: 1.375;
}

#zpravodajstvi ul.inline {
  padding: 0;
  margin: 20px 0;
}

#kalendarAkci .dok li.u {
  padding-left: 150px;
}
#kalendarAkci .dok li.u strong img {
  margin-left: -150px;
}

/** DOKUMENTY NA TITULCE **/
#pozicovani {
  overflow: hidden;
}
#pozicovani .poz {
  margin-bottom: 25px;
}
@media screen and (min-width: 801px) {
  #pozicovani {
    display: flex;
  }
  #pozicovani .poz:first-child {
    padding-right: 4%;
    flex-grow: 1;
  }
  #pozicovani .poz:first-child .dok,
#pozicovani .poz:first-child h2.cvi {
    padding-left: 30px;
  }
  #pozicovani .poz.-neprehlednete {
    width: 33.3333333333%;
    flex-shrink: 0;
    flex-grow: 0;
    min-width: 18em;
  }
  #pozicovani .poz.-neprehlednete .dok {
    background-color: #B02F2F;
    color: #ffffff;
    padding: 30px;
  }
  #pozicovani .poz.-neprehlednete .dok li .card > strong a,
#pozicovani .poz.-neprehlednete .dok li .card > strong a:link,
#pozicovani .poz.-neprehlednete .dok li .card > strong a:visited {
    color: #ffffff;
    text-decoration: none;
  }
  #pozicovani .poz.-neprehlednete .dok li .card > strong a:hover,
#pozicovani .poz.-neprehlednete .dok li .card > strong a:focus,
#pozicovani .poz.-neprehlednete .dok li .card > strong a:active {
    text-decoration: underline;
  }
  #pozicovani .poz.-neprehlednete .dok li a,
#pozicovani .poz.-neprehlednete .dok li a:link,
#pozicovani .poz.-neprehlednete .dok li a:visited {
    color: #ffffff;
  }
  #pozicovani .poz.-neprehlednete h2.cvi {
    color: #B02F2F;
  }
}
#pozicovani .cards li {
  margin-top: 0;
  box-sizing: border-box;
  margin-bottom: 20px;
  padding-top: 0;
  padding-bottom: 0;
}
@media screen and (min-width: 650px) {
  #pozicovani .skryty {
    display: none;
  }
}

/*******************************
* OSNOVA S UPOUTÁVKAMI V POZADÍ	
*******************************/
.obrodkazy li {
  display: block;
  margin-bottom: 13px;
  background-image: none;
  position: relative;
}
.obrodkazy li::before {
  content: normal !important;
}
.obrodkazy a {
  font-size: 1.25rem;
  font-family: Arial, Helvetica, sans-serif;
  background-repeat: no-repeat;
  background-position: 0 50%;
  padding-left: 65px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 48px;
}
.obrodkazy strong {
  font-weight: normal;
}
.obrodkazy a,
.obrodkazy a:link,
.obrodkazy a:visited {
  text-decoration: none;
}
.obrodkazy a:hover,
.obrodkazy a:focus,
.obrodkazy a:active {
  text-decoration: underline;
}

/*****************************
* VÝPIS AKCÍ S CEDULKAMI
*****************************/
#vypisakci h3.cvi {
  font-size: 1.5625rem;
  margin-bottom: 10px;
}
#vypisakci .dok li {
  display: flex;
  margin: 14px 0;
  padding-bottom: 14px;
  width: 100%;
}
#vypisakci .dok li strong a,
#vypisakci .dok li strong a:link,
#vypisakci .dok li strong a:visited {
  color: orange;
}
#vypisakci .dok .datum,
#vypisakci .dok .prazdnyDatum {
  width: 5.52em;
  text-align: center;
  flex-shrink: 0;
  padding: 0.26em 0;
  font-family: RobotoWeb;
}
#vypisakci .dok .den,
#vypisakci .dok .mesic {
  display: block;
  line-height: 1.2;
}
#vypisakci .dok .den {
  font-size: 1.375rem;
  font-weight: bold;
  background: gold;
  color: #101010;
  padding: 0.3em 0;
}
#vypisakci .dok .mesic {
  font-size: 1rem;
  background: #444444;
  color: #ffffff;
  text-transform: lowercase;
  padding: 0.5em 0;
}
#vypisakci .dok .denPredlozka,
#vypisakci .dok .rok,
#vypisakci .dok .den .tecka {
  position: absolute;
  left: -9999px;
  right: 9990px;
}
#vypisakci .dok .prazdnyDatum {
  background: transparent;
}
#vypisakci .dok .obsahAkce {
  padding-right: 15px;
  padding-left: 15px;
  flex-grow: 1;
}

/*****************************
* MODUL DALŠÍ ZDROJE NA TITULCE
*****************************/
.titulodkazy li {
  display: block;
  background-image: none;
  margin: 4px 0;
  padding: 0;
  position: relative;
}
.titulodkazy li::before {
  content: normal !important;
}
.titulodkazy li a {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
}
.titulodkazy li strong:first-child a,
.titulodkazy li strong:first-child a:link,
.titulodkazy li strong:first-child a:visited {
  color: #232323;
}
.titulodkazy li div {
  margin: 2px 0 0 0;
}
.titulodkazy strong {
  font-weight: normal;
}

.dok li.urd-line {
  position: relative;
  margin: 0;
  padding: 15px 20px;
}
.dok li.urd-line:nth-child(even) {
  background-color: #ededed;
}
.dok li.urd-line::after {
  content: normal;
}
.dok li.urd-line .urd-left {
  position: relative;
}
.dok li.urd-line strong a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
}
@media screen and (min-width: 801px) {
  .dok li.urd-line {
    display: table;
    box-sizing: border-box;
    width: 100%;
  }
  .dok li.urd-line .urd-left,
.dok li.urd-line .urd-right {
    display: table-cell;
  }
  .dok li.urd-line .urd-left {
    padding-right: 1.5em;
  }
  .dok li.urd-line .urd-right {
    width: 13em;
  }
}

.bodkazy .ui li > strong:first-child a,
.bodkazy .ui li > strong:first-child a:link,
.bodkazy .ui li > strong:first-child a:visited {
  color: #444444;
}

/**********************
*** DLAŽDICOVÉ MENU ***
**********************/
.mapa-stranek-2016 .odkazy.souvisejici ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px 35px -15px;
  list-style-type: none;
  padding: 0;
  text-align: center;
}
body.leftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
}
@media screen and (max-width: 699px) {
  body.leftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li {
    width: 100%;
  }
}
@media screen and (min-width: 700px) and (max-width: 799px) {
  body.leftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li {
    width: 50%;
    margin-left: 0%;
  }
  body.leftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 800px) and (max-width: 999px) {
  body.leftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li {
    width: 100%;
    margin-left: 0%;
  }
  body.leftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li:nth-of-type(1n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1000px) {
  body.leftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li {
    width: 50%;
    margin-left: 0%;
  }
  body.leftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
body.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
}
@media screen and (max-width: 699px) {
  body.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li {
    width: 100%;
  }
}
@media screen and (min-width: 700px) and (max-width: 999px) {
  body.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li {
    width: 50%;
    margin-left: 0%;
  }
  body.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1000px) {
  body.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li {
    width: 33.3333333333%;
    margin-left: 0%;
  }
  body.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
.mapa-stranek-2016 .odkazy.souvisejici ul li {
  box-sizing: border-box;
  padding: 0 15px;
  background-color: transparent;
  background-clip: padding-box;
  margin-top: 0;
  margin-bottom: 24px;
  text-align: left;
  position: relative;
}
.mapa-stranek-2016 .odkazy.souvisejici ul li:last-child {
  margin-bottom: 0;
}
.mapa-stranek-2016 .odkazy.souvisejici ul li strong + div {
  font-style: normal;
  display: none;
}
.mapa-stranek-2016 .odkazy.souvisejici ul li > strong:first-child {
  font-weight: normal;
}
.mapa-stranek-2016 .odkazy.souvisejici ul li > strong:first-child a {
  font-weight: normal;
  font-family: RobotoWeb;
  font-size: 1.25rem;
  display: flex;
  padding: 0 0 24px 0;
  border-bottom: 11px #E4E4E4 solid;
  justify-content: space-between;
  align-items: flex-start;
}
.mapa-stranek-2016 .odkazy.souvisejici ul li > strong:first-child a::after {
  content: url(images/menu_fw.png);
  display: inline-block;
  width: 17px;
  height: 16px;
  margin-left: 14px;
  flex-grow: 0;
  position: relative;
  top: 0.2em;
}
.mapa-stranek-2016 .odkazy.souvisejici ul li > strong:first-child a,
.mapa-stranek-2016 .odkazy.souvisejici ul li > strong:first-child a:link,
.mapa-stranek-2016 .odkazy.souvisejici ul li > strong:first-child a:visited {
  text-decoration: none;
}
.mapa-stranek-2016 .odkazy.souvisejici ul li > strong:first-child a:hover,
.mapa-stranek-2016 .odkazy.souvisejici ul li > strong:first-child a:focus,
.mapa-stranek-2016 .odkazy.souvisejici ul li > strong:first-child a:active {
  text-decoration: underline;
  border-bottom-color: #B02F2F;
  color: #B02F2F;
}

.vybrane-odkazy .ui li {
  margin: 0 0 8px 0;
}
.vybrane-odkazy .ui li > strong:first-child {
  font-size: 0.9375rem;
}
.vybrane-odkazy .ui li > strong:first-child a,
.vybrane-odkazy .ui li > strong:first-child a:link,
.vybrane-odkazy .ui li > strong:first-child a:visited {
  text-decoration: underline;
}
.vybrane-odkazy .ui li > strong:first-child a:hover,
.vybrane-odkazy .ui li > strong:first-child a:focus,
.vybrane-odkazy .ui li > strong:first-child a:active {
  text-decoration: none;
}

.dok-slider {
  overflow-x: auto;
}
.dok-slider ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  white-space: nowrap;
  font-size: 0;
  transition: transform 0.6s ease-in-out;
  display: flex;
}
.dok-slider ul li {
  display: inline-block;
  width: 100%;
  flex-shrink: 0;
  white-space: normal;
  margin: 0;
  padding: 12% 0;
  font-size: 1rem;
  text-align: left;
  color: #ffffff;
  text-shadow: 0 3px 4px rgba(0, 0, 0, 0.5);
  background-color: #303030;
  background-repeat: no-repeat;
  background-size: cover;
}
.dok-slider ul li .slider-texty {
  display: inline-block;
  padding: 20px 30px;
  background-color: rgba(0, 0, 0, 0.5);
}
.dok-slider ul li .dok-popis,
.dok-slider ul li .dok-nazev {
  font-size: 3.125rem;
}
.dok-slider ul li .dok-popis {
  margin-bottom: 30px;
}
.dok-slider ul li .dok-nazev {
  font-weight: bold;
  text-transform: uppercase;
}
.dok-slider ul li .dok-poznamka {
  display: flex;
  align-items: center;
  font-size: 1.0625rem;
}
.dok-slider ul li .dok-vice {
  display: inline-block;
  flex-grow: 0;
  padding: 16px 20px;
  margin: 0 20px 0 0;
  font-weight: 500;
  background-color: #FC6F2A;
  text-shadow: none;
}
.dok-slider ul li .dok-vice,
.dok-slider ul li .dok-vice:link,
.dok-slider ul li .dok-vice:visited {
  text-decoration: none;
}
.dok-slider ul li .dok-vice:hover,
.dok-slider ul li .dok-vice:focus,
.dok-slider ul li .dok-vice:active {
  text-decoration: underline;
}
.dok-slider[data-liteslider-js] {
  overflow-x: hidden;
}

/*****************************
* FORMULÁŘE
*****************************/
/*****************************
* PLACEHOLDERY PRO PRVKY
*****************************/
.titulformular label {
  line-height: 1.2;
  text-align: left;
  padding: 3px 2px;
  display: block;
}
.titulformular label.label-skryty {
  padding: 0 2px;
  position: relative;
}
.titulformular label.label-skryty::after {
  content: "";
  z-index: 2;
}

/****************************/
/*************************************************
* VŠECHNY BĚŽNÉ PRVKY VE FORMULÁŘÍCH
*************************************************/
.format {
  background-color: #ffffff;
  color: #000000;
  height: 46px;
  border-radius: 0;
  line-height: 1.2142857143;
  font-size: 0.9375rem;
}
.format:-ms-input-placeholder {
  color: inherit !important;
}
.format::-ms-input-placeholder {
  color: inherit !important;
}
.format::placeholder {
  color: inherit !important;
}
.format:focus {
  border-color: #437DCC;
}

.format,
fieldset {
  border: 1px #cccccc solid;
}

:not(.fvlevo) > .fvyraz #hv {
  font-size: 1.1875rem;
  height: 54px;
  border-width: 2px;
}

textarea.format {
  min-height: 92px;
}

select.format[multiple] {
  height: 138px;
}

.btn {
  background-color: #B02F2F;
  border: 1px #B02F2F solid;
  color: #ffffff;
  height: 46px;
}

.fbtn .btn {
  padding: 0 2em;
  font-size: 1rem;
}

/************************************************/
.fkont {
  margin-top: 20px;
}
.fkont form {
  padding: 21px 36px 23px 36px;
}
.fkont :not(.fvlevo) > .fvyraz label[for=hv] {
  font-size: 1rem;
}

.formular,
.fkont form,
.nastaveni {
  background-color: #eeeeee;
  color: #202020;
}
.formular a,
.formular a:link,
.formular a:visited,
.fkont form a,
.fkont form a:link,
.fkont form a:visited,
.nastaveni a,
.nastaveni a:link,
.nastaveni a:visited {
  color: #202020;
}

.fcesta,
.fcesta a, .fcesta a:link, .fcesta a:visited, .fcesta a:hover, selector:focus, selector:active {
  background-color: #228be6;
  color: #ffffff;
}

/*******************************
*	ABO A NAPIŠTE NÁM NA TITULCE
*******************************/
.titulformular {
  margin-top: -1em;
}
.titulformular label.label-skryty::after {
  background: transparent url(images/frm_prekryti.svg) repeat 0 0;
}
.titulformular .format {
  margin: 2px 0;
  padding: 5px 15px;
  width: 100%;
  background-color: transparent;
  color: #ffffff;
  border-color: #7C7C7C;
  background-repeat: no-repeat;
  background-position: 0 0;
}
.titulformular img {
  position: absolute;
  right: 0;
  top: 0;
}
.titulformular .opiste {
  position: relative;
}
.titulformular .captchaformat {
  padding-right: 90px;
}
.titulformular .fbtn,
.titulformular .btn {
  clear: none;
  margin: 0;
}
.titulformular .btn {
  display: inline-block;
  font-weight: 500;
  font-size: 1.0625rem;
}
.titulformular p {
  padding: 0 0 10px 0;
  margin: 0;
}

/***************
*	HLEDÁNÍ
***************/
#hledani {
  overflow: hidden;
  margin: 20px 0;
}
@media screen and (min-width: 501px) {
  #hledani {
    float: right;
    width: 320px;
  }
}
#hledani .pole {
  position: relative;
  z-index: 5;
}
#hledani input:focus {
  outline: 0;
}
#hledani .format {
  width: 100%;
  margin: 0;
  height: 36px;
  border-color: #ffffff;
  color: #000000;
  padding: 4px 30px 4px 10px;
  background-repeat: no-repeat;
  background-position: 0 50%;
}
#hledani .btn {
  border: 0;
  position: absolute;
  z-index: 2;
  top: 2px;
  right: 2px;
  height: auto;
  border-radius: 0;
  background-color: transparent;
  color: #000000;
}

.inline-form {
  padding-bottom: 3em;
}

.inline-form #skryvaci,
.inline-form-word {
  display: inline-block;
}

.inline-form-search {
  position: relative;
}
.inline-form-search input,
.inline-form-search label,
.inline-form-search textarea,
.inline-form-search select {
  font-size: 1rem;
}

.inline-form-word {
  margin: 0.25em 0;
}

.inline-form-input {
  box-sizing: border-box;
  max-width: 12em;
  padding: 2px 10px;
}

.inline-form-datepicker {
  padding-right: 30px;
  background-image: url(/aspinclude/vismoWeb5/html/images/abonent_calendar_2016.svg);
  background-size: 18px auto;
  background-repeat: no-repeat;
  background-position: 98% 48%;
  max-width: 8em;
}

.inline-form-input,
.inline-form-select {
  border-radius: 4px;
  border: 1px #888888 solid;
  height: 1.75em;
}

.inline-form .fbtn {
  margin-bottom: -3em;
}

.inline-form-select {
  max-width: 13em;
  padding-right: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-overflow: ellipsis;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='15px' height='15px' viewBox='0 0 15 15' xml:space='preserve'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23444444' d='M4,0l4,7H0L4,0z M4,15L0,8h8L4,15z'/%3E%3C/svg%3E");
  background-position: 100% 50%;
  background-repeat: no-repeat;
}

.inline-form #prepinac {
  margin-top: 2em;
}
@media screen and (min-width: 601px) {
  .inline-form #prepinac {
    position: absolute;
    left: 0;
    bottom: -2.3em;
    font-size: 1rem;
  }
}

.fullscreen-search {
  transition: background-color 0.1s ease-out;
  position: fixed;
  top: 0;
  left: -999em;
  z-index: 19000;
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
  text-align: center;
  font-family: RobotoWeb;
  background: rgba(57, 57, 57, 0.9);
}
.fullscreen-search:target, .fullscreen-search[data-is-shown=true] {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: auto;
  height: auto;
  backdrop-filter: blur(8px) grayscale(100%);
}
.fullscreen-search form {
  font-size: 1rem;
  width: 80%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 10% 0 0 0;
  text-align: left;
  color: #ffffff;
  line-height: 1.4;
}
@media screen and (min-height: 35em) {
  .fullscreen-search form {
    padding-top: 14%;
  }
}
@media screen and (orientation: portrait) {
  .fullscreen-search form {
    padding-top: 33%;
  }
}
.fullscreen-search .fullscreen-search-close {
  transition: background-color 0.15s ease-out;
  position: absolute;
  top: 0;
  right: 0;
  width: 72px;
  height: 72px;
  cursor: pointer;
  background: transparent url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23fff' d='M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z'/%3E%3C/svg%3E") no-repeat 50% 50%;
}
.fullscreen-search .fullscreen-search-close:hover,
.fullscreen-search .fullscreen-search-close:focus,
.fullscreen-search .fullscreen-search-close:active {
  background-color: #000000;
}
.fullscreen-search .fullscreen-search-label {
  font-size: 1.125rem;
  display: block;
  margin: 0 0 0.9em 0;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
}
.fullscreen-search .pole {
  position: relative;
}
.fullscreen-search .fullscreen-search-button {
  appearance: none;
  border: 0;
  background: transparent;
  color: #ffffff;
  width: 2.5em;
  height: 2.5em;
  position: absolute;
  right: 0;
  top: 50%;
  margin: -1.3em 0 0 0;
}
@media screen and (min-width: 1025px) {
  .fullscreen-search .fullscreen-search-button {
    width: 5em;
    height: 5em;
    margin: -2.5em 0 0 0;
  }
}
.fullscreen-search .fullscreen-search-input {
  box-sizing: border-box;
  font-size: 2.375rem;
  width: 100%;
  font-family: RobotoWeb;
  background: transparent;
  color: #ffffff;
  outline: 0;
  font-weight: bold;
  padding: 0 1em 0 0;
  border-width: 0 0 2px 0;
  border-color: rgba(255, 255, 255, 0.8);
  border-style: dotted;
}
.fullscreen-search .fullscreen-search-input:focus {
  box-shadow: none !important;
}
.fullscreen-search .fullscreen-search-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}
@media screen and (min-width: 601px) {
  .fullscreen-search .fullscreen-search-input {
    font-size: 2.625rem;
  }
}
@media screen and (min-width: 1025px) {
  .fullscreen-search .fullscreen-search-input {
    font-size: 5rem;
  }
}
@media screen and (min-width: 1601px) {
  .fullscreen-search .fullscreen-search-input {
    font-size: 5.375rem;
  }
}

/***********************
*	TABULKY
***********************/
.blokposun {
  background-color: #eeeeee;
}

.seznam {
  border: 0;
}
.seznam th a,
.seznam th a:link,
.seznam th a:visited,
.seznam th a:hover,
.seznam th a:focus,
.seznam th a:active {
  color: #ffffff;
}
.seznam td,
.seznam th {
  border: 1px white solid;
  border-width: 0 1px 1px 0;
}
.seznam td:first-child,
.seznam th:first-child {
  padding-left: 36px;
}
.seznam td:last-child,
.seznam th:last-child {
  border-right: 0;
}
.seznam th {
  font-size: 1.125rem;
  font-family: RobotoWeb;
  line-height: 1.1;
  font-weight: normal;
  background-color: #228be6;
  color: #ffffff;
}
.seznam th a,
.seznam th a:link,
.seznam th a:visited {
  color: #ffffff;
}
.seznam td {
  color: black;
  border-width: 1px 1px 0 0;
}
.seznam .b1 td {
  border-color: #ffffff;
}
.seznam .b2 td, .seznam #isvs .hodnota td, #isvs .seznam .hodnota td {
  border-color: #ebebeb;
}

.b1 {
  background-color: #ebebeb;
}

.b2, #isvs .hodnota {
  background-color: #ffffff;
}

/**********************
*	FOTOGALERIE
**********************/
.nahledy li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
}
@media screen and (max-width: 399px) {
  .nahledy li {
    width: 100%;
  }
}
@media screen and (min-width: 400px) and (max-width: 799px) {
  .nahledy li {
    width: 49%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 800px) {
  .nahledy li {
    width: 32%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
.nahledy div > a {
  background: #cccccc;
  border: 2px transparent solid;
  border-radius: 4px;
  min-height: 200px;
}
.nahledy div > a:hover,
.nahledy div > a:focus,
.nahledy div > a:active {
  background: transparent;
  border-color: #228be6;
}

/**********************
*	PŘEHLEDY GALERIÍ
**********************/
.galerie-2016 .ui li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
}
@media screen and (max-width: 399px) {
  .galerie-2016 .ui li {
    width: 100%;
  }
}
@media screen and (min-width: 400px) and (max-width: 1023px) {
  .galerie-2016 .ui li {
    width: 49%;
    margin-left: 2%;
  }
  .galerie-2016 .ui li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1024px) {
  .galerie-2016 .ui li {
    width: 32%;
    margin-left: 2%;
  }
  .galerie-2016 .ui li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

/***********************
*	ZÁLOŽKY
***********************/
.zalozky {
  font-size: 1.25rem;
  line-height: 1.1;
  font-family: RobotoWeb;
  font-weight: normal;
}
.zalozky li {
  padding: 0;
  margin: 0 0.5em 0.5em 0;
  float: left;
  position: relative;
  display: block;
}
.zalozky li.azalozka {
  background: transparent none;
}
.zalozky a {
  display: block;
  text-decoration: none;
  margin: 0;
  position: relative;
  z-index: 20;
  cursor: pointer;
  padding: 10px 36px 12px;
}
.zalozky a,
.zalozky a:link,
.zalozky a:visited {
  background-color: turquoise;
  color: #433923;
}
.zalozky a:hover,
.zalozky a:focus,
.zalozky a:active {
  text-decoration: underline;
}
.zalozky .azalozka a,
.zalozky .azalozka a:link,
.zalozky .azalozka a:visited,
.zalozky .azalozka a:hover,
.zalozky .azalozka a:focus,
.zalozky .azalozka a:active {
  background-color: bisque;
  color: #000000;
  text-decoration: none;
  cursor: default;
}

#zahlavi2 {
  margin: 20px 0;
}

#zalozkynadpis {
  padding: 15px 0 0 0;
}

.vych_pol {
  font-size: 1rem;
  text-align: right;
  padding: 0 0 10px 0;
  margin: 0 0 10px 0;
  text-transform: uppercase;
}
.vych_pol strong {
  font-weight: normal;
}

a[id*=k0] {
  height: 0;
  overflow: hidden;
  line-height: 0;
  font-size: 0;
  color: transparent !important;
}

/****************************
*	S VYPNUTÝM JS NA TITULCE
****************************/
.bezjs {
  padding: 20px 0 15px 0;
}
.bezjs .inline li::before {
  color: #404040;
}

#keskryti {
  display: inline;
}

/****************************
*	SE ZAPNTÝM JS NA TITULCE
****************************/
#titul .zalozky {
  font-size: 1.3125rem;
  line-height: 1.1;
  font-family: RobotoWeb;
  text-transform: uppercase;
}
#titul .zalozky .inline li::before {
  content: normal;
}
#titul .zalozky a {
  padding: 15px;
}
#titul .zalozky a,
#titul .zalozky a:link,
#titul .zalozky a:visited {
  background-color: turquoise;
  color: #433923;
}
#titul .zalozky a:hover,
#titul .zalozky a:focus,
#titul .zalozky a:active {
  text-decoration: underline;
}
#titul .zalozky .azalozka a,
#titul .zalozky .azalozka a:link,
#titul .zalozky .azalozka a:visited,
#titul .zalozky .azalozka a:hover,
#titul .zalozky .azalozka a:focus,
#titul .zalozky .azalozka a:active {
  background-color: bisque;
  color: #000000;
}

@media screen and (max-width: 649px) {
  #linkynakotvy {
    display: none;
  }
  #titul .poz h2.cist {
    position: static;
    width: auto;
    height: auto;
  }
  #titul .poz .skryty {
    display: block;
  }
}
/******************
* MODULY
******************/
#uvodtitul {
  overflow: hidden;
  margin: 0 0 30px 0;
}

#upozorneni {
  padding: 15px;
  background-color: pink;
}
#upozorneni,
#upozorneni h2.cvi,
#upozorneni a, #upozorneni a:link, #upozorneni a:visited {
  color: #444444;
}
#upozorneni h2.cvi,
#upozorneni p {
  display: inline-block;
}

#kalendarAkci .editor {
  margin-top: 40px;
  margin-bottom: 40px;
}

#obalmbannery {
  min-width: 320px;
  background: #ffffff;
}

#mbannery {
  padding: 30px 0;
  color: #000000;
  margin: 0;
}
#mbannery .editor {
  text-align: center;
}
#mbannery .editor a,
#mbannery .editor a:link,
#mbannery .editor a:visited,
#mbannery .editor a:hover,
#mbannery .editor a:focus,
#mbannery .editor a:active {
  color: #000000 !important;
}
#mbannery li {
  margin-top: 0;
  margin-bottom: 0;
}

#jmeniny {
  padding: 6px 12px 2px 15px;
  text-align: left;
}
#jmeniny * {
  display: inline;
}

#kontakt address {
  display: block;
  font-style: normal;
  margin: 0 0 25px 0;
  line-height: 1.5333333333;
}
#kontakt br {
  line-height: 0.5;
}
#kontakt strong {
  font-weight: bold;
}
#kontakt .kontprvni {
  font-weight: normal;
}

#anketa p {
  padding: 0;
  margin: 0 0 1em 0;
}
#anketa ul {
  list-style-type: none;
  padding: 0;
  margin: 8px 0 5px 0;
}
#anketa ul li {
  margin-bottom: 8px;
}
#anketa .hlas {
  margin-top: 3px;
  z-index: 1;
}
#anketa .hlas div {
  height: 12px;
  background-color: #228be6;
}

#pocitadlo {
  margin: 15px 0 0 0;
  text-align: center;
  padding-bottom: 15px;
}
#pocitadlo ul.inline {
  padding-left: 0;
  display: inline;
}
#pocitadlo ul.inline li span {
  margin: 0 0 0 2px;
}
@media screen and (max-width: 599px) {
  #pocitadlo li {
    display: block !important;
  }
  #pocitadlo li + li::before {
    content: "";
    margin: 0;
  }
}

/*******************************
* KALENDÁŘE
*******************************/
.mesice {
  display: flex;
  justify-content: space-between;
  color: #2a2a2a;
}
#stranka .mesice .c-actual-month {
  text-align: center;
}

#kalakci {
  max-width: 30em;
  width: 104%;
  margin-left: -2%;
}
@media screen and (min-width: 451px) {
  #kalakci {
    width: 106%;
    margin-left: -3%;
  }
}
#kalakci .calcells {
  width: 100%;
  border-spacing: 0 1.5em;
  font-size: 0.875rem;
}
#kalakci .calcells td,
#kalakci .calcells th {
  box-sizing: border-box;
  position: relative;
}
#kalakci .calcells th {
  top: -6px;
  color: #2a2a2a;
}
#kalakci .calcells td {
  width: 14.28%;
  height: 2em;
  text-align: center;
  font-size: 0.875rem;
  z-index: 0;
}
#kalakci .calcells td::before {
  content: "";
  position: absolute;
  display: block;
  width: 2em;
  height: 2em;
  border-radius: 500em;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -1em 0 0 -1em;
  box-sizing: border-box;
  z-index: -1;
}
#kalakci .calcells td.wkday::before {
  background-color: transparent;
  border: 2px blue solid;
}
#kalakci .calcells td.wkday, #kalakci .calcells td.wkday a {
  color: #2a2a2a;
  text-decoration: none;
}
#kalakci .calcells td.curdate::before {
  background-color: magenta;
}
#kalakci .calcells td.curdate, #kalakci .calcells td.curdate a {
  color: #ffffff;
}
#kalakci .calcells td.curdate:hover, #kalakci .calcells td.curdate a:hover {
  color: #ffffff !important;
}
#kalakci .calcells td.notmnth::before {
  background-color: transparent;
  border: 1px blue solid;
}
#kalakci .calcells td.notmnth, #kalakci .calcells td.notmnth a {
  color: #2a2a2a;
  text-decoration: none;
}
#kalakci .calcells td.notmnth a > span {
  background-color: yellow;
  border: 0px transparent solid;
  color: #2a2a2a;
}
#kalakci .calcells td a {
  line-height: 2em;
  position: absolute;
  width: 2em;
  left: 50%;
  margin-left: -1em;
  top: 0;
  bottom: 0;
  font-size: 0.875rem;
}
#kalakci .calcells td a:hover {
  background-color: transparent !important;
  color: #2a2a2a !important;
  text-decoration: underline;
  border-radius: 500em;
}
#kalakci .calcells td a > span {
  font-weight: normal;
  font-size: 0.75rem;
  position: absolute;
  top: -0.9em;
  right: -0.4em;
  width: 1.6em;
  height: 1.6em;
  line-height: 1.7em;
  background-color: yellow;
  border: 0px transparent solid;
  color: #2a2a2a;
  border-radius: 500em;
}
@media screen and (min-width: 451px) {
  #kalakci .calcells td {
    height: 2.5em;
  }
  #kalakci .calcells td::before {
    width: 2.5em;
    height: 2.5em;
    margin: -1.25em 0 0 -1.25em;
  }
  #kalakci .calcells td a {
    line-height: 2.5em;
    width: 2.5em;
    margin-left: -1.25em;
  }
  #kalakci .calcells td a > span {
    right: -0.7em;
  }
}
@media screen and (min-width: 601px) {
  #kalakci .calcells {
    font-size: 1rem;
  }
  #kalakci .calcells td, #kalakci .calcells td a {
    font-size: 1rem;
  }
  #kalakci .calcells td a > span {
    font-size: 0.875rem;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #kalakci .calcells td {
    line-height: 2em;
  }
  #kalakci .calcells td::before {
    margin-top: 0;
    top: 0;
  }
  #kalakci .calcells td a {
    bottom: auto;
  }
}

.akce-podle-data {
  clear: both;
}

#stranka #kalakci {
  margin-top: 10px;
  margin-bottom: 25px;
  max-width: 41.25em;
  float: left;
}
#stranka #kalakci .c-arrow {
  display: inline-block;
}
#stranka #kalakci .c-arrow:hover {
  text-decoration: underline;
}
#stranka #kalakci .c-before-month, #stranka #kalakci .c-next-month {
  padding: 0;
}
#stranka #kalakci .c-before-month .space, #stranka #kalakci .c-before-month .c-moth, #stranka #kalakci .c-before-month .c-year, #stranka #kalakci .c-next-month .space, #stranka #kalakci .c-next-month .c-moth, #stranka #kalakci .c-next-month .c-year {
  display: none;
}
#stranka #kalakci .c-actual-month {
  padding-left: 0;
  text-align: center;
}
#stranka #kalakci .mesice {
  margin: 0 auto;
  display: table;
  width: 100%;
  line-height: 0.8;
  table-layout: fixed;
  border-spacing: 1px 0;
  margin-bottom: 10px;
  font-size: 1.25rem;
}
#stranka #kalakci .mesice .c-actual-month {
  display: table-cell;
  width: 71%;
  vertical-align: bottom;
}
#stranka #kalakci .mesice + p {
  margin: 20px 0;
}
#stranka #kalakci .mesice a {
  display: table-cell;
  vertical-align: bottom;
  text-decoration: underline;
  color: #232323;
  text-align: center;
}
#stranka #kalakci .mesice a:hover {
  text-decoration: none !important;
}
#stranka #kalakci .calcells td.wkday::before {
  background-color: transparent;
  border: 2px blue solid;
}
#stranka #kalakci .calcells td.wkday, #stranka #kalakci .calcells td.wkday a {
  color: #2a2a2a;
}
#stranka #kalakci .calcells td.curdate::before {
  background-color: magenta;
}
#stranka #kalakci .calcells td.curdate, #stranka #kalakci .calcells td.curdate a {
  color: #ffffff;
}
#stranka #kalakci .calcells td.curdate:hover, #stranka #kalakci .calcells td.curdate a:hover {
  color: #ffffff !important;
}
#stranka #kalakci .calcells td.notmnth::before {
  background-color: transparent;
  border: 1px magenta solid;
}
#stranka #kalakci .calcells td.notmnth, #stranka #kalakci .calcells td.notmnth a {
  color: #2a2a2a;
}
#stranka #kalakci .calcells td.notmnth a > span {
  background-color: yellow;
  border: 1px gold solid;
  color: #2a2a2a;
}
#stranka #kalakci .calcells td a:hover {
  background-color: transparent !important;
  color: #2a2a2a !important;
}
#stranka #kalakci .calcells td a > span {
  background-color: yellow;
  border: 1px gold solid;
  color: #2a2a2a;
}

/*****************************************
* MIXINY A PROMĚNNÉ PRO TABULKOVÉ MENU
*****************************************/
/****************************************/
/******************************
* HORIZONTÁLNÍ MENU A VÝBĚRY
******************************/
#obalmenu1 {
  position: relative;
  z-index: 15;
}

#menu1 ul {
  margin-bottom: 24px;
}
#menu1 li {
  font-size: 1.625rem;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0;
  background-color: #ffffff;
}
#menu1 li.akt {
  border: 5px #000 solid;
}
#menu1 li a {
  display: flex;
  font-family: RobotoWeb;
  flex-direction: column;
}
#menu1 li a .menunazev {
  padding: 32px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#menu1 li a .menunazev::after {
  content: url(images/menu_fw.png);
  display: inline-block;
  width: 17px;
  height: 16px;
  flex-grow: 0;
  margin-left: 14px;
}
#menu1 li a .menupopis {
  padding: 55% 30px 32px 30px;
  font-size: 1.125rem;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: 100% auto;
}
#menu1 li a,
#menu1 li a:link,
#menu1 li a:visited {
  text-decoration: none;
  color: #343434;
}
#menu1 li #osmakth,
#menu1 li #osmakth:link,
#menu1 li #osmakth:visited,
#menu1 li #osmakth:hover,
#menu1 li #osmakth:focus,
#menu1 li #osmakth:active {
  cursor: text;
  text-decoration: none;
}

@media screen and (max-width: 500px) {
  #menu1 li {
    margin-bottom: 15px;
    background-color: #e4e4e4;
  }
}
@media screen and (min-width: 501px) and (max-width: 800px) {
  #menu1 ul {
    display: flex;
    flex-wrap: wrap;
  }
  #menu1 li {
    box-sizing: border-box;
    width: 49%;
    margin-left: 2%;
    float: left;
    background-color: #e4e4e4;
    margin-bottom: 15px;
  }
  #menu1 li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 801px) {
  #menu1 ul {
    display: flex;
    margin-left: 0;
    margin-right: 0;
  }
  #menu1 li {
    margin-top: -3.55em;
    flex: 1 1 25%;
    box-sizing: border-box;
    margin-left: 0;
    margin-right: 0;
  }
  #menu1 li + li {
    border-left: 1px #707070 solid;
  }
}
#menu1 #p1 .menupopis {
  border-top: 11px #FC6F2A solid;
  background-image: url(images/obor1.jpg);
}

#menu1 #p2 .menupopis {
  border-top: 11px #009DFE solid;
  background-image: url(images/obor2.jpg);
}

#menu1 #p3 .menupopis {
  border-top: 11px #FFD800 solid;
  background-image: url(images/obor3.jpg);
}

.menu-hlava {
  text-align: center;
  padding: 20px 0;
}
@media screen and (max-width: 1023px) {
  .menu-hlava {
    order: 3;
    width: 100%;
  }
}
.menu-hlava ul li {
  display: inline-block;
  margin: 0 0.75em;
}
.menu-hlava ul li > strong:first-child {
  font-size: 1.125rem;
}
.menu-hlava ul li a,
.menu-hlava ul li a:link,
.menu-hlava ul li a:visited {
  color: #343434;
  text-decoration: none;
}
.menu-hlava ul li a:hover,
.menu-hlava ul li a:focus,
.menu-hlava ul li a:active {
  text-decoration: underline;
}

/**********************
*** DLAŽDICOVÉ MENU ***
**********************/
.nadmenu {
  display: block;
  font-weight: normal;
  padding: 33px 20px 12px 88px;
  font-family: RobotoWeb;
  font-size: 1.625rem;
  font-weight: 500;
  line-height: 1.1;
  color: #B02F2F;
  font-weight: bold;
}
.nadmenu a,
.nadmenu a:link,
.nadmenu a:visited {
  font-family: RobotoWeb;
  color: #B02F2F;
  text-decoration: none;
}
.nadmenu a:hover,
.nadmenu a:focus,
.nadmenu a:active {
  text-decoration: underline;
}
.nadmenu a {
  position: relative;
}
.nadmenu a::before {
  content: url(images/arr_back.svg);
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0.15em;
  left: -86px;
}

/**********************
* MOBILNÍ MENU
**********************/
#menu {
  background-color: #ffffff;
  padding: 20px;
}
#menu ul {
  padding-left: 20px;
}
#menu ul a {
  padding: 5px 20px;
  margin-left: -20px;
  display: block;
}
#menu ul a,
#menu ul a:link,
#menu ul a:visited {
  color: #303030;
}
#menu ul #osmakt,
#menu ul #osmakt:link,
#menu ul #osmakt:visited,
#menu ul #osmakt:hover,
#menu ul #osmakt:focus,
#menu ul #osmakt:active {
  background-color: yellow;
  color: purple;
  text-decoration: none;
}

/***********************
* DESKTOPOVÉ MENU
***********************/
@media screen and (min-width: 801px) {
  .nadmenu {
    display: block;
  }
  #nadmenu {
    font-size: 0.875rem;
    display: none;
    font-weight: normal;
    padding: 0;
    white-space: nowrap;
  }
  #menu {
    background-color: #ffffff;
    padding: 0 0 50px 0;
  }
  #menu ul {
    font-size: 1.25rem;
    font-family: RobotoWeb;
    line-height: 1.2;
    padding: 28px 0 5px 0;
  }
  #menu ul a,
#menu ul a:link,
#menu ul a:visited {
    color: #303030;
    text-decoration: none;
  }
  #menu ul .aktodk,
#menu ul .aktodk:link,
#menu ul .aktodk:visited {
    color: #232323;
    text-decoration: none;
  }
  #menu ul a:hover,
#menu ul a:focus,
#menu ul a:active {
    text-decoration: underline;
  }
  #menu ul #osmakth,
#menu ul #osmakth:link,
#menu ul #osmakth:visited,
#menu ul #osmakth:hover,
#menu ul #osmakth:focus,
#menu ul #osmakth:active {
    background-color: transparent;
    text-decoration: none;
    cursor: default;
    color: #232323;
    border: 0;
  }
  #menu ul a {
    padding: 10px 0 10px 0;
    display: block;
    position: relative;
    z-index: 10;
    margin-left: 0;
    text-decoration: none;
  }
  #menu li {
    margin: 0;
    padding: 0 20px 0 88px;
    position: relative;
    display: block;
  }
  #menu li.akt {
    background-color: #ffffff;
  }
}
#google_translate_element {
  display: inline-block;
  position: relative;
  display: none;
  overflow: hidden;
  flex-shrink: 0;
}
#google_translate_element.ready {
  margin-left: 32px;
  display: inline-block;
}
#google_translate_element .goog-te-select {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  opacity: 0 !important;
}
#google_translate_element .goog-te-gadget {
  border: 0;
  width: 100%;
  padding-bottom: 1px;
  background-color: transparent;
  font-size: 0 !important;
  position: relative;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
}
#google_translate_element .goog-te-gadget .goog-te-gadget-simple {
  display: none !important;
}
#google_translate_element .goog-te-gadget > * {
  font-size: 1rem !important;
}
#google_translate_element .goog-te-gadget::before {
  content: "";
  width: 23px;
  height: 23px;
  background-image: url(/aspinclude/vismoWeb5/html/images/gte-icon.svg) !important;
  background-position: 0 0 !important;
  background-size: 100% auto;
  background-repeat: no-repeat;
}
#google_translate_element .goog-te-gadget .goog-te-gadget-icon + span {
  display: none;
}

#jazyky:empty {
  display: none;
}
#jazyky ul {
  list-style-type: none;
  margin: 0 5px 0 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
}
#jazyky ul li {
  margin: 0 5px;
  font-size: 0;
}
#jazyky ul li a::before {
  font-size: 0.875rem;
}
#jazyky ul li .googleTranslateActiveLanguage {
  font-weight: bold;
}
#jazyky ul li .language-en::before {
  content: "EN";
}
#jazyky ul li .language-de::before {
  content: "DE";
}
#jazyky ul li .language-fr::before {
  content: "FR";
}
#jazyky ul li .language-pl::before {
  content: "PL";
}
#jazyky ul li .language-ru::before {
  content: "RU";
}
#jazyky ul li .language-hu::before {
  content: "HU";
}
#jazyky ul li .language-cs::before {
  content: "CS";
}
#jazyky ul li a,
#jazyky ul li a:link,
#jazyky ul li a:visited {
  text-decoration: none;
}
#jazyky ul li a:hover,
#jazyky ul li a:focus,
#jazyky ul li a:active {
  text-decoration: underline;
}

body[style] {
  top: 0 !important;
}

#goog-gt-tt,
#goog-gt-tt + div > object,
iframe.goog-te-menu-frame,
iframe.goog-te-banner-frame {
  display: none !important;
}