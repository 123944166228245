/*************************
* ZÁHLAVÍ
*************************/

/*************************
* HLAVA
*************************/

#obalhlava {
   position: relative;
   z-index: 13;
   background-color: #ffffff;
   color: #000000;

   @include link {
      color: #343434;
   }
}

#hlava {
   @extend %sf;
   clear: both;
   position: relative;
   z-index: 3;

   .centrovany {
      padding-top: 35px;
      padding-bottom: 40px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      position: relative;

      .hlava__doplnek {
         margin-top: 25px;
      }

      @include min(600px) {
         justify-content: space-between;

         .hlava__doplnek {
            margin-top: 0;
         }
      }

      .slogan {
         position: absolute;
         left: 0;
         top: 100%;
         margin: 0;
         padding: 12px 62px;
         transform: translateY(-50%);
         background-color: #1d1d1b;
         color: white;
         font-size: rem(15px);
         display: none;

         @include min(600px) {
            display: block;
         }
      }

      &::after {
         content: normal;
      }
   }
}

#znak {
   margin: 0;
}

#nazev {
   text-align: center;
   position: relative;
   margin: 0;

   @include bp('<= 600px') {
      margin-left: auto;
      margin-right: auto;
   }

   a {
      z-index: 30;
      display: inline;
      text-decoration: none !important;
      outline-width: 0;
      font-size: 0;

      &::after {
         content: '';
         @extend %fill;
      }
   }

   img {
      font-size: rem(16px);
      line-height: 1;
   }
}

@include bp('> 500px') {
   #nazev {
      text-align: left;
      float: left;
      display: flex;
   }
}

/****************************
* HLAVNÍ OBRÁZEK A BLENDER
****************************/

#obalobrazek {
   display: none;
}

@include bp('> 600px') {
   #obalobrazek {
      display: block;
      clear: both;
   }

   #obrazek {
      position: relative;
   }

   #administraceobrazky {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 4;
   }
}

#blender {
   height: 0;
   margin: 0 auto;
   position: relative;
   z-index: 2;
   overflow: hidden;
   padding-bottom: ratio(1920px, 600px);
   background: #303030 url(images/blenderImages/0.jpg##{$cache-hash}) no-repeat 50% 50%;
   background-size: cover;
}

.blender__buttons {
   position: absolute;
   z-index: 3;
   right: 1em;
   bottom: 1em;
}

.blender__button {
   border-radius: 0;
   -webkit-appearance: none;
   -moz-appearance: none;
   background: transparent none;
   box-shadow: none;

   &:focus {
      box-shadow: none;
   }
}

@keyframes blend-in {
   from {
      display: block;
      opacity: 0;
   }
   to {
      display: block;
      opacity: 1;
   }
}

.blender__item {
   background: {
      position: 50% 50%;
      repeat: no-repeat;
      size: cover;
   }
}

.blender__item {
   @include fill;
}

.blender__item.blender__item--animating {
   animation: blend-in 800ms ease-out;
   animation-fill-mode: forwards;
}

#lista {
   @extend %sf;
   text-align: right;
   padding: 0;
   position: relative;

   ul {
      @extend %reset-list;
      position: relative;

      &.inline {
         display: inline;
      }
   }

   li {
      &.skip {
         white-space: nowrap;
         margin: 0;
         position: absolute;
         display: block;
         top: 0 !important;
         right: 0 !important;
         max-width: 100%;
         padding: 1px;
         border: 0;

         a {
            text-align: center;
            border: 0;
            position: absolute;
            right: 0;
            top: -500px;
            z-index: 0;
            padding: 0 2px;
            background-color: #ffffff;
            color: #000000;
         }

         a:focus,
         a:active {
            z-index: 10 !important;
            position: absolute !important;
            right: 10px;
            top: 5px;
         }
      }
   }
}

#jazyky {
   margin: 0;
   padding: 0;
   list-style-type: none;
   display: inline-block;

   li {
      &::before {
         content: '';
         margin: 0 9px 0 10px;
      }

      @include link {
         color: #707070;
         text-decoration: underline;
      }

      @include link-over {
         text-decoration: none;
      }
   }
}

.pomocne {
   display: inline-flex;
   align-items: center;
   text-align: right;
   list-style-type: none;
   margin: 0;
   padding: 0;

   li {
      margin: 0 0 0 1em;
      display: inline-flex;
      align-items: center;

      &.facebook {
         margin-left: 30px;
      }
   }

   a {
      display: inline-block;
   }
}
